/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid hsla(210,18%,87%,1);
  background: #001529;
}

.header-left {
  display: flex;
  justify-content: flex-start;
}

.header-right {
  display: flex;
  justify-content: flex-end;
}

.main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.markdown-container {
  padding: 20px;
}

.component {
  flex: 1 0 50%;
  box-sizing: border-box;
  padding: 20px;
  height: 97vh;
  overflow-y: scroll; /* Always shows scroll bar */
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@media (max-width: 600px) {
  .component {
      flex: 1 0 100%;
      height: 50%;
  }
}

.text-editor {
  width: 100%;
}

.function_button {
  margin: 0 auto;
}

.pop-over {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code {
  width: 25vh;
}